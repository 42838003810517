/* @import url('https://fonts.googleapis.com/css?family=Josefin+Sans');
  
body {
  font-family: 'Josefin Sans', cursive;
} */

.wrapper {
  display: grid;
  gap: 1rem;
  grid-auto-flow: column;
  grid-auto-columns: 100%;

  overflow-x: auto;
  overscroll-behavior-inline:contain;


  scroll-snap-type: inline mandatory;
}

.wrapper-inline > * {
  scroll-snap-align: start;
}

.some-days{
  gap:1;
  grid-auto-flow:column;
  grid-auto-columns:18%;
  overflow-x: auto;
}

.some-days > * {
  scroll-snap-align: start;
}

.sam {
  grid-auto-flow:row;
  grid-auto-rows:100%;
  overflow-x: auto;
}

.msg-box:before{
  position: absolute;
  content:'';
  top:-1px;
  left:-4px;
  width:.3rem;
  height:.3rem;
  background-color: #f5f5f5; 
  border-bottom-left-radius: 100%;
  border-top: .1px solid #e0e0e0;
  border-left: .1px solid #e0e0e0;
}

.msg-box-1:before{
  position: absolute;
  content:'';
  top:-1px;
  left:-4px;
  width:.3rem;
  height:.3rem;
  background-color: #130808; 
  border-bottom-left-radius: 100%;
  border-top: .1px solid #e0e0e0;
  border-left: .1px solid #e0e0e0;
}


.elip{
  display: -webkit-box;
  -webkit-line-clamp:3;
  -webkit-box-orient:vertical;
  overflow:hidden;
}

.elip2{
  display: -webkit-box;
  -webkit-line-clamp:2;
  -webkit-box-orient:vertical;
  overflow:hidden;
}
.elipSingle{
  display: -webkit-box;
  -webkit-line-clamp:1;
  -webkit-box-orient:vertical;
  overflow:hidden;
}

#comment-input::-webkit-scrollbar{
   width: 0px;
}

.comment-input::-webkit-scrollbar{
  width:0px;
}



.chat-box-small-input::-webkit-scrollbar{
   width: 0px;
}

.text-area-small::-webkit-scrollbar{
   width: 0px;
}

.text-area::-webkit-scrollbar{
   width: 0px;
}



.bak{
  background-color: #e8ecf0;
}

.like{
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter:"FlipH";
}

.pro-pic-upload {
  border-radius: 45% 56% 70% 30% / 85% 69% 31% 15%;
  border: 2px solid black
}

.video-js {
  width: 100%;
  height:auto;
  aspect-ratio:16/9;
}

@media screen and (min-width:1024px) {
       
      .chat-space::-webkit-scrollbar{
        width: 10px;
        background-color: rgb(240, 234, 234);
     }
     
     .message-box::-webkit-scrollbar{
        width: 10px;
        background-color: rgb(240, 234, 234);
     }

     .message-box::-webkit-scrollbar-track{
          border-radius: 5px;
      }

     .chat-space::-webkit-scrollbar-track{
          border-radius: 5px;
      }

      .feed-placeholder{
        opacity: 0.3;
      }
      
      .chat-space::-webkit-scrollbar-thumb{
          background-color: rgb(197, 193, 201);
          border-radius: 14px;
          border-radius: 5px;
          cursor:pointer;
      }
      .message-box::-webkit-scrollbar-thumb{
        background-color: rgb(197, 193, 201);
          border-radius: 14px;
          border-radius: 5px;
          cursor:pointer;
      }
      
}

/* skekton styles */

.skeleton{
  background: #ddd;
  border-radius: 4px;
}

.skeleton.text{
  width:100%;
  height: 12px;
}

.skeleton.title{
  width: 50%;
  height: 10px;
  margin-bottom: 6px;
}

.skeleton.name{
  width: 50%;
  height: 10px;
  margin-bottom: 6px;
}

.skeleton.day{
  width: 40%;
  height: 10px;
  margin-bottom: 6px;
}

.skeleton.avatar{
  width: 100%;
  height: 300px;
}

.skeleton.thumbnail{
  width: 100px;
  height: 100px;
}
.skeleton.post{
  width: 100%;
  height: 20rem;
}

